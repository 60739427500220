import { memo } from 'react';
import {generateCsp} from '../utils/generateCsp';

const Meta = (props) => {

        return (
            <>
                <base href="/" />
                <meta name="robots" content="follow" />
                <meta name="rating" content="general" />
                <meta name="revisit-after" content="0 days" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta httpEquiv="Content-Security-Policy" content={generateCsp()} />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="#AB1752" />
                <meta name="theme-color" content="#AB1752" />
                <meta name="color-scheme" content="dark light" />
                <meta name="msapplication-TileColor" content="#AB1752" />

                <link rel="preconnect" href="https://www.google-analytics.com" />

                <link rel="preconnect" href="https://api.odete.com.br" />
                <link rel="dns-prefetch" href="https://api.odete.com.br" />

                <link rel="preload" href="/assets/fonts/odete_font.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
                <link rel="apple-touch-icon" href="/assets/icons/apple-touch-icon.png" />
                <link rel="apple-touch-icon" sizes="180x180" href="/assets/icons/apple-touch-icon.png" />
                <link rel="apple-touch-icon" sizes="167x167" href="/assets/icons/apple-touch-icon.png" />
                <link rel="apple-touch-icon" sizes="152x152" href="/assets/icons/apple-touch-icon.png" />
                <link rel="apple-touch-icon" sizes="76x76" href="/assets/icons/apple-touch-icon.png" />

                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />                
                <link rel="dns-prefetch" href="https://fonts.gstatic.com" />
                
                <link rel="apple-touch-startup-image" href="/assets/icons/iphone5_splash.png" media="(width: 320px) and (height: 568px)" />
                <link rel="apple-touch-startup-image" href="/assets/icons/iphone6_splash.png" media="(width: 375px) and (height: 667px)" />
                <link rel="apple-touch-startup-image" href="/assets/icons/iphoneplus_splash.png" media="(width: 621px) and (height: 1104px)" />
                <link rel="apple-touch-startup-image" href="/assets/icons/iphonex_splash.png" media="(width: 375px) and (height: 812px)" />
                <link rel="apple-touch-startup-image" href="/assets/icons/iphonexr_splash.png" media="(width: 414px) and (height: 896px)" />
                <link rel="apple-touch-startup-image" href="/assets/icons/iphonexsmax_splash.png" media="(width: 414px) and (height: 896px)" />
                <link rel="apple-touch-startup-image" href="/assets/icons/ipad_splash.png" media="(width: 768px) and (height: 1024px)" />
                <link rel="apple-touch-startup-image" href="/assets/icons/ipadpro1_splash.png" media="(width: 834px) and (height: 1112px)" />
                <link rel="apple-touch-startup-image" href="/assets/icons/ipadpro3_splash.png" media="(width: 834px) and (height: 1194px)" />
                <link rel="apple-touch-startup-image" href="/assets/icons/ipadpro2_splash.png" media="(width: 1024px) and (height: 1366px)" />
                <link rel="icon" type="image/x-icon" href="/assets/icons/favicon.ico" />
                <link rel="icon" type="image/png" sizes="32x32" href="/assets/icons/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/assets/icons/favicon-16x16.png" />
                <link rel="mask-icon" href="/assets/icons/safari-pinned-tab.svg" color="#5bbad5" />
                <link rel="manifest" href="/manifest.json" />
                <link rel="manifest" href="/site.webmanifest"/>

                <meta name="author" content="Felipe Huggler Luz - Odete Serviços Digitais LTDA" />
                <meta name="google" content="notranslate" />
                <link rel="chrome-webstore-item" href="https://chrome.google.com/webstore/detail/odete/fphcengkpcbdanedfdikjmiaconjplfa" />

                <meta property="fb:app_id" content="1579397852288566" />
                <meta property="og:type" content="website" />

                <meta property="og:image:type" content="image/png" />
                <meta property="og:site_name" content="Odete" />

                {
                    (!props.disabledImage) && <>
                        <meta property="og:image" content='https://odete.com.br/assets/images/logo-odete-facebook.png' />
                        <meta property="og:image:secure_url" content='https://odete.com.br/assets/images/logo-odete-facebook.png' />
                        <meta name="twitter:image" content='https://odete.com.br/assets/images/logo-odete-facebook.png' />
                    </>
                }

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:creator" content="@_huggler" />
                <meta name="twitter:site" content="@_huggler" />
                <meta name="twitter:domain" content="odete.com.br" />
                <meta name="twitter:title" content="Odete - Contrate diaristas de forma rápida, simples e objetiva" />
                <meta name="twitter:description" content='Precisando contratar diarista? Contrate diaristas de forma rápida, simples e objetiva na Odete - A maior plataforma para diarista e contratante do Brasil' />
                
                {/*<script data-ad-client="ca-pub-9250112169123238" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
                <script async custom-element="amp-auto-ads" src="https://cdn.ampproject.org/v0/amp-auto-ads-0.1.js"></script>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                            window.addEventListener('DOMContentLoaded', init, false);
                            function init(){
                                    var flex = document.getElementById('__next');
                                            const observer = new MutationObserver(function(mutations, observer){
                                            flex.style.height = "";
                                    });
                                    observer.observe(flex, {
                                            attributes: true,
                                            attributeFilter: ['style']
                                    });
                            }
                    `
                    }}
                />
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                        (function(c,l,a,r,i,t,y){
                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "bs3livq62g");
                    `
                    }}
                />*/}
    </>)
};

export default memo(Meta);